import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  {
    path: 'ballot-list',
    loadChildren: () => import('./pages/ballot-list/ballot-list.module').then( m => m.BallotListPageModule)
  },
  {
    path: 'ballot-details',
    loadChildren: () => import('./pages/ballot-details/ballot-details.module').then( m => m.BallotDetailsPageModule)
  },
  {
    path: 'ballot-details/:id',
    loadChildren: () => import('./pages/ballot-details/ballot-details.module').then( m => m.BallotDetailsPageModule)
  },
  {
    path: 'ballot-cast',
    loadChildren: () => import('./pages/ballot-cast/ballot-cast.module').then( m => m.BallotCastPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/resources/resources.module').then( m => m.ResourcesPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'ballot-receipt',
    loadChildren: () => import('./pages/ballot-receipt/ballot-receipt.module').then( m => m.BallotReceiptPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
